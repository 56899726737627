<template>
    <div class='promocode-page'>
        <h4 class='page-title'>{{ getActionName }}</h4>
        <div class='widget'>
            <FormBuilder
                class='row'
                v-if='pageType === "edit" ? pageData : true'
                :form-data='formData'
                :save='saveDataFunc'
            />
        </div>
    </div>
</template>

<script>
import FormBuilder from '../../components/FormBuilder/FormBuilder';
export default {
    name: 'PromocodesDetail',
    components: { FormBuilder },
    data() {
        return {
            pageType: null,
            pageData: null,
            saveDataFunc: null,
            formData: {
                'title': {
                    'type': 'text',
                    'label': 'Название',
                    'validation': 'required|max:120',
                    'value': null,
                },
                'discount': {
                    'type': 'number',
                    'label': 'Скидка %',
                    'validation': 'required|numeric|max:99',
                    'value': null
                },
                'starts_from': {
                    'type': 'dateTime',
                    'label': 'Дата начала',
                    'validation': 'required|dateTime',
                    'value': null
                },
                'ends_at': {
                    'type': 'dateTime',
                    'label': 'Дата окончания',
                    'validation': 'dateTime',
                    'value': null
                },
                'endless': {
                    'type': 'bool',
                    'class': 'col-3',
                    'label': 'Многоразовый',
                    'validation': 'bool',
                    'value': false
                },
                'is_used': {
                    'type': 'bool',
                    'class': 'col-3',
                    'label': 'Уже использован',
                    'validation': 'bool',
                    'value': false
                },
                'only_new_student': {
                  'type': 'bool',
                  'class': 'col-2',
                  'label': 'Только для новых студентов',
                  'validation': 'bool',
                  'value': false
                },
                'is_one_phone': {
                    'type': 'bool',
                    'class': 'col-2',
                    'label': 'Только один раз на номер',
                    'validation': 'bool',
                    'value': false
                },
                'only_first_pay': {
                  'type': 'bool',
                  'class': 'col-2',
                  'label': 'Только для первой оплаты на номер',
                  'validation': 'bool',
                  'value': false
                },
                'used_at': {
                    'type': 'dateTime',
                    'label': 'Когда был использован',
                    'validation': 'dateTime',
                    'value': null
                },
                'code': {
                    'type': 'text',
                    'label': 'Код',
                    'validation': 'required|max:120',
                    'value': null
                },
                'condition_min_count': {
                    'type': 'text',
                    'label': 'Минимальное к-во уроков',
                    'validation': 'numeric',
                    'value': null
                },
                'condition_min_price': {
                    'type': 'text',
                    'label': 'Минимальная цена покупки',
                    'validation': 'numeric',
                    'value': null
                },
                'condition_max_count': {
                  'type': 'text',
                  'label': 'Максимальное к-во уроков',
                  'validation': 'numeric',
                  'value': null
                },
                'profit_description': {
                  'type': 'text',
                  'label': 'Описание бонуса',
                  'validation': 'max:120',
                  'value': null
                },
            },
        };
    },
    computed: {
        getActionName() {
            if (this.pageType === 'create') {
                return 'Создать промокод';
            }

            if (this.pageType === 'edit') {
                return 'Редактировать промокод';
            }
            return '';
        },
    },
    methods: {
        extractPageType() {
            const path = this.$route.path.split('/');
            this.pageType = path[path.length - 1];
        },
        async saveNewData(formData) {
            try {
                const {data} = await this.$axios.post('api/promocode', formData);
                this.$toasted.success(`Промокод ${formData.code} создан!`, {position: "bottom-right"})
            } catch (e) {
                this.handleAxiosError(e)
            }
        },
        handleAxiosError(error) {
            const {data} = error.response;
            if (data.error) {
                this.$toasted.error(data.message, {position: 'bottom-right'})
                return;
            }
            if (data.errors) {
                this.$toasted.error(data.message, {position: 'bottom-right'})
                return;
            }
            alert(error.message)
        },
        async saveEditData(formData) {
            try {
                const id = this.$route.params.id
                const {data} = await this.$axios.put('api/promocode/' + id, formData);
                this.$toasted.success(`Промокод ${formData.code} отредактирован!`, {position: "bottom-right"})
            } catch (e) {
                this.handleAxiosError(e)
            }
        },
        fillFormData(data) {
            for (const key in this.formData) {
                this.formData[key]['value'] = data[key]
            }
        },
        async fetchEditData() {
            const id = this.$route.params.id
            if (!id) {
                this.$toasted.error("Нельзя редактировать без ID", {position: "bottom-right"})
                return;
            }
            try {
                const {data} = await this.$axios.get('/api/promocode/' + id)
                if (data) {
                    this.fillFormData(data)
                    this.pageData = data
                }
            } catch (e) {
                alert(e)
            }
        }
    },
    async created() {
        this.extractPageType();
        if (this.pageType === 'edit') {
            await this.fetchEditData();
            this.saveDataFunc = this.saveEditData
        }
        if (this.pageType === 'create') {
            this.saveDataFunc = this.saveNewData
        }
    },
};
</script>

<style lang='scss' scoped>
.row {
    &::v-deep {
        .b-form-btn-label-control.form-control {
            height: 32px;
        }
    }
}
</style>